import React, { Component } from 'react';
import * as Colyseus from "colyseus.js";
import { Route } from 'react-router';
import Lottie from 'react-lottie';
import LoggingService from "services/logging";
import * as Sentry from "@sentry/react";

import Loading from "components/Loading";
import ClientContent from "components/ClientContent";

import questionFooter from "images/question-footer.png";

import mooAnim from "animations/mooAnim.js";

import "animate.css";
import styles from 'components/ClientStyles.module.scss';
import nosleep from 'nosleep.js';
import ClientMenuIcon from './ClientMenuIcon';

var noSleep = new nosleep();
var supportsVibrate = "vibrate" in navigator;

const GameStates = {
    Loading: "loading",
    Tutorial: "tutorial",
    Idle: "idle",
    Question: "question",
    Answers: "answers",
    PinkCow: "pink_cow",
    Results: "results",
    EndGame: "end_game",
};

const ViewTypes = {
    Default: "_DEFAULT",
    Tutorial: "_TUTORIAL",
    Question: "_QUESTION",
    Moo: "_MOO",
    Answers: "_ANSWERS",
};

const gameId = "herd_mentality";

export class Client extends Component {
    static displayName = Client.name;

    constructor(props) {
        super(props);

        this.client = new Colyseus.Client(process.env.REACT_APP_GAME_SERVER_URL);
        this.state = {
            roomId: 0,
            room: null,
            myId: null,
            roomState: null,
            redirect: null,
            redirectURL: "",
            connected: false,
            reconnectionToken: "",
            logStreamId: "",

            showDefaultView: true,
            showTutorialView: false,
            showQuestionView: false,
            showMooView: false,
            showAnswersView: false,
            gameState: GameStates.Loading,

            question: {},
            questionId: "",
            answer: "",
            canMoo: true,
            isHerdWrangler: false,
            players: [],
            answerTitle: "",
            assignPinkCow: false,
            roundTimer: 0,
            mooIsPaused: true,
            isPaused: false,

            player: null,

            title: "",
            content: "",
            votedSkip: false,

            forceVotedSkip: false,
            submittedAnswers: false,
            submittedPinkcow: false,

            gotLocationPing: true,
            connectionIssue: false,
            hostConnected: false,

            //player: {
            //    name: "SCOTT",
            //    avatar: 22
            //},
            //room: {},
            //connected: true,
            //question: {
            //    question: "What's the best thing to come out of France: The Statue of Liberty, champagne or baguettes?"
            //},
            //players: [
            //    {
            //        herdData: {
            //            answer: "testing",

            //        }
            //    },
            //    {
            //        herdData: {
            //            answer: "asdsadsad asd dsdfsdhfu sdlnk lskdnsdfkln kss",

            //        }
            //    },
            //    {
            //        herdData: {
            //            answer: "testing",

            //        }
            //    },
            //    {
            //        herdData: {
            //            answer: "asdsadsad asd dsdfsdhfu sdlnk lskdnsdfkln kss",

            //        }
            //    },
            //    {
            //        herdData: {
            //            answer: "asdsadsad asd dsdfsdhfu sdlnk lskdnsdfkln kss",

            //        }
            //    },
            //    {
            //        herdData: {
            //            answer: "asdsadsad asd dsdfsdhfu sdlnk lskdnsdfkln kss",

            //        }
            //    },
            //    {
            //        herdData: {
            //            answer: "testing",

            //        }
            //    },
            //    {
            //        herdData: {
            //            answer: "asdsadsad asd dsdfsdhfu sdlnk lskdnsdfkln kss",

            //        }
            //    },

            //],
            //isHerdWrangler: true,
            //answerTitle: "Select who is in the majority... (pick at least 2 players)",
        };
        this.locationCheckInterval = null;


        //window.onbeforeunload = () => {
        //    if (!this.state.redirect) {
        //        if (this.state.room) {
        //            this.state.room.leave(false);
        //        }
        //    }
        //}
    }

    componentDidMount() {
        this.setTags();

        setTimeout(() => {
            this.doReconnect();
        }, 1500);

        //this.setView(ViewTypes.Answers);

        document.addEventListener('click', function enableNoSleep() {
            document.removeEventListener('click', enableNoSleep, false);
            noSleep.enable();
        }, false);
    }

    componentDidUpdate() {
        if ([GameStates.Loading, GameStates.EndGame].includes(this.state.gameState) == false && this.state.redirectURL.length == 0) {
            window.onbeforeunload = () => true;
        } else {
            window.onbeforeunload = undefined;
        }
    }

    setTags() {
        const token = this.getQueryStringValue('token');
        Sentry.setTag('isPlayer', true);

        if (token) {
            const [roomId, reconnectToken] = token.split(':');
            Sentry.setTag('roomId', roomId);
            Sentry.setTag('reconnectToken', reconnectToken);
        }
    }

    setView(viewType) {
        switch (viewType) {
            case ViewTypes.Tutorial:
                this.setState({ showDefaultView: false, showTutorialView: true, showQuestionView: false, showMooView: false, showAnswersView: false, });
                break;
            case ViewTypes.Question:
                this.setState({ showDefaultView: false, showTutorialView: false, showQuestionView: true, showMooView: false, showAnswersView: false, });
                break;
            case ViewTypes.Moo:
                this.setState({ showDefaultView: false, showTutorialView: false, showQuestionView: false, showMooView: true, showAnswersView: false, });
                break;
            case ViewTypes.Answers:
                this.setState({ showDefaultView: false, showTutorialView: false, showQuestionView: false, showMooView: false, showAnswersView: true, });
                break;
            case ViewTypes.Default:
            default:
                this.setState({ showDefaultView: true, showTutorialView: false, showQuestionView: false, showMooView: false, showAnswersView: false, });
                break;
        }
    }

    getQueryStringValue(key) {
        return decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
    }

    doNewQuestion(question) {
        if (supportsVibrate) navigator.vibrate(500);
        this.setState({ question, });
        this.setView(ViewTypes.Question);
    }

    updateAnswer = (event) => {
        let newAnswer = event.target.value;
        this.setState({ answer: event.target.value, });

        if (newAnswer.length > 45) {
            console.log("adjusting answer length");
            let str = newAnswer;
            let removalCount = str.length - 44;
            console.log(removalCount);
            str = str.substring(0, str.length - removalCount);
            newAnswer = str;
            this.setState({ answer: newAnswer, });
        }
        this.state.room.send("update_answer", { answer: newAnswer, });
    }

    submitAnswer(force = false) {
        if (this.state.answer.length > 0 || force) {
            this.state.room.send("player_answer", {});
            this.setState({ answer: "", question: {} });
            this.setView(ViewTypes.Moo);
        }
    }

    doMoo(mooIsPaused) {
        if (this.state.canMoo) {
            this.state.room.send("moo", { mooIsPaused, });
            this.setState({ canMoo: false, mooIsPaused: false, });
            setTimeout(() => {
                this.setState({ mooIsPaused: true, });
            }, 500);
            setTimeout(() => {
                this.setState({ canMoo: true });
            }, 2500);
        }
    }

    doAnswers() {
        if (this.state.isHerdWrangler && !this.state.submittedAnswers) {
            if (supportsVibrate) navigator.vibrate(500);
            this.setState({ answerTitle: "Select who is in the majority... (pick at least 2 players)", answer: "", });
            this.setView(ViewTypes.Answers);
        } else {
            this.setState({ answer: "", });
            this.setView(ViewTypes.Default);
        }
    }


    getArrayOfPlayers(statePlayers) {
        let arrPlayers = [];
        statePlayers.forEach((value, key) => {
            arrPlayers.push(value);
        });

        if (arrPlayers.length > 0) {
            console.log(arrPlayers[0]);
        }
        return arrPlayers;
    }

    selectAnswer(id) {
        let players = [...this.state.players];

        if (this.state.assignPinkCow) {
            players.forEach((value) => {
                value.herdData.selected = false;
                this.state.room.send("answer_selected", { id: value.id, selected: false, });
            });
        }

        let pos = players.map(function (e) { return e.id; }).indexOf(id);
        let player = { ...players[pos] };

        let newValue = player.herdData.selected ? false : true;
        player.herdData.selected = newValue;

        players[pos] = player;

        this.setState({ players });
        this.state.room.send("answer_selected", { id, selected: newValue, });
    }


    submitSelectedAnswers = () => {
        let ids = [];
        this.state.players.map(x => {
            if (x.herdData.selected) {
                ids.push(x.id);
            }
        });

        if (ids.length >= 2) {
            this.state.room.send("answers_chosen", { idList: ids, });
            this.setView(ViewTypes.Default);
            this.setState({ submittedAnswers: true, });
        }
    }

    showAssignPinkCow() {
        if (this.state.isHerdWrangler && !this.state.submittedPinkcow) {
            if (supportsVibrate) navigator.vibrate(500);
            this.setState({ assignPinkCow: true, answerTitle: "Who should have the pink cow? (who was the odd one out)" });
            this.setView(ViewTypes.Answers);
        } else {
            this.setState({ answer: "", });
            this.setView(ViewTypes.Default);
        }
    }

    skipSelectedAnswers = () => {
        this.state.room.send("skip_answers_chosen", {});
        this.showAssignPinkCow();
        this.setState({ submittedAnswers: true, });
    }

    submitPinkCow = (idOverride = null) => {
        let id = null;

        if (idOverride) {
            id = idOverride;
        } else {
            this.state.players.map(x => {
                if (x.herdData.selected) {
                    id = x.id;
                }
            });
        }

        if (id != null) {
            this.setState({ assignPinkCow: false, answerTitle: "", });
            this.state.room.send("assign_pink_cow", { id, });
            this.setState({ submittedPinkcow: true, });
            this.setView(ViewTypes.Default);
        }
    }

    skipPinkCow = () => {
        this.setState({ assignPinkCow: false, answerTitle: "", });
        this.state.room.send("skip_assign_pink_cow", {});
        this.setState({ submittedPinkcow: true, });
        this.setView(ViewTypes.Default);
    }

    doCatchup(gameState) {
        const player = { ...this.state.player };

        switch (gameState || this.state.gameState) {
            case GameStates.Question:
                if (player.herdData.ready === false) {
                    if (this.state.question.id !== this.state.questionId) {
                        this.state.room.send("request_question", {});
                    }
                }
                break;
            case GameStates.Tutorial:
                if (this.state.votedSkip === false && this.state.forceVotedSkip === false) {
                    this.setView(ViewTypes.Tutorial);
                } else {
                    this.setView(ViewTypes.Default);
                }
                break;
            case GameStates.Answers:
                if (!this.state.showAnswersView && !this.state.submittedAnswers) {
                    this.doAnswers();
                }
                break;
            case GameStates.PinkCow:
                if (!this.state.showAnswersView && !this.state.submittedPinkcow) {
                    this.showAssignPinkCow();
                }
                break;
            default:
                this.setView(ViewTypes.Default);
                break;
        }
    }

    startLocationChecks() {
        this.state.room.send("location_check", { gameId, });
        this.locationCheckInterval = setInterval(() => {
            if (this.state.gotLocationPing) {
                this.setState({ gotLocationPing: false, connectionIssue: false, });
            } else {
                this.setState({ connectionIssue: true, });
            }
            this.state.room.send("location_check", { gameId, });
        }, 10000);
    }

    skipTutorial() {
        this.state.room.send("vote_skip");
        this.setState({ forceVotedSkip: true, });
        this.setView(ViewTypes.Default);
    }
    goToLobby() {
        this.setState({ redirectURL: `${this.getRedirectURL()}/?token=${this.state.reconnectionToken}` });
        this.state.room.leave(false);
        if (this.locationCheckInterval) clearInterval(this.locationCheckInterval);
    }

    getRedirectURL(display = false) {
        let url = display ? process.env.REACT_APP_GAME_CITY_URL_DISPLAY : process.env.REACT_APP_GAME_CITY_URL;
        if (this.state.room) {
            if (this.state.room.name != "game_city_room") {
                url = display ? process.env.REACT_APP_HOME_URL_DISPLAY : process.env.REACT_APP_HOME_URL;
            }
        }
        return url;
    }

    updateToken(token) {
        var url = new URL(window.location.href);

        try {
            window.history.replaceState(null, null, (url.pathname) + (`?token=${token}`));
        } catch (e) {
            console.warn(e)
        }
    }

    updatePlayerProperty(property, value) {
        let player = { ...this.state.player };
        player.herdData[property] = value;
        this.setState({ player, });
    }

    checkAndAddPlayer(player) {
        if (!this.state.players.find(elem => elem.id === player.id)) {
            console.log("Adding player to bonus actions : ", player.id);
            this.setState((prevState) => {
                return { players: [...prevState.players, player] }
            });
        }
    }

    doReconnect = () => {
        const token = this.getQueryStringValue("token")
        console.log(token)

        if (this.state.connected == false) {
            this.client.reconnect(token).then(room => {
                console.log(room.sessionId, "joined", room.name);

                this.setState({ room: room, roomId: room.id, myId: room.sessionId, connected: true, reconnectionToken: room.reconnectionToken, });
                this.updateToken(room.reconnectionToken);
                room.send("update_player_token", { reconnectionToken: room.reconnectionToken });

                room.onStateChange.once((state) => {
                    console.log("this is the first room state!", state);
                    const player = state.players[this.state.myId];
                    if (!player) window.location = this.getRedirectURL();
                    Sentry.setUser({ id: player.uniqueId });
                    this.startLocationChecks();
                    LoggingService.streamLog(state.uniqueId, `Player ${this.state.myId} ${player.name} Reconnected to Herd Mentality, Reconnection Token: ${room.reconnectionToken}`);

                    this.setState({
                        roomState: state,
                        logStreamId: state.uniqueId,
                    });
                });
                room.onStateChange((state) => {
                    console.log("room has new state:", state);
                    this.setState({
                        roomState: state,
                    });
                });

                room.state.listen("isPaused", (currentValue, previousValue) => {
                    this.setState({ isPaused: currentValue, });
                });
                room.state.herdData.listen("gameState", (currentValue, previousValue) => {
                    console.log(`gameState change detected : ${currentValue}`);
                    this.setState({ gameState: currentValue });
                    this.doCatchup(currentValue);
                });
                room.state.herdData.listen("herdWranglerId", (currentValue, previousValue) => {
                    const isHerdWrangler = currentValue === this.state.myId;
                    this.setState({ isHerdWrangler, });
                });
                room.state.herdData.listen("questionId", (currentValue, previousValue) => {
                    this.setState({ questionId: currentValue, });
                });

                room.state.host.listen("connected", (value) => {
                    this.setState({ hostConnected: value });
                });

                room.state.players.onAdd((player, key) => {
                    this.checkAndAddPlayer(player);
                    player.listen("connected", (currentValue, previousValue) => {
                        let statePlayers = [...this.state.players];
                        let index = statePlayers.findIndex(elem => elem.id === player.id);
                        statePlayers[index].connected = currentValue;
                        this.setState({ players: statePlayers });
                    });

                    if (player.id === room.sessionId) {
                        this.setState({ player: player, });

                        player.listen("votedSkip", (currentValue, previousValue) => {
                            this.setState({ votedSkip: currentValue, });
                        });
                        player.herdData.onChange(() => {
                            this.doCatchup();
                        });

                        player.herdData.listen("answer", (currentValue, previousValue) => {
                            this.updatePlayerProperty("answer", currentValue);
                            this.setState({ answer: currentValue, });
                        });
                        player.herdData.listen("selected", (currentValue, previousValue) => {
                            this.updatePlayerProperty("selected", currentValue);
                        });
                        player.herdData.listen("confirmed", (currentValue, previousValue) => {
                            this.updatePlayerProperty("confirmed", currentValue);
                        });
                        player.herdData.listen("ready", (currentValue, previousValue) => {
                            this.updatePlayerProperty("ready", currentValue);
                        });
                        player.herdData.listen("score", (currentValue, previousValue) => {
                            this.updatePlayerProperty("score", currentValue);
                        });
                        player.herdData.listen("beenWrangler", (currentValue, previousValue) => {
                            this.updatePlayerProperty("beenWrangler", currentValue);
                        });
                        player.herdData.listen("hasPinkCow", (currentValue, previousValue) => {
                            this.updatePlayerProperty("hasPinkCow", currentValue);
                        });
                    } else {
                        player.herdData.listen("answer", (currentValue, previousValue) => {
                            let statePlayers = [...this.state.players];
                            let pos = statePlayers.map(function (e) { return e.id; }).indexOf(player.id);
                            statePlayers[pos].herdData.answer = currentValue;
                            this.setState({ players: statePlayers, });
                        });
                    }
                });


                room.onMessage("end_tutorial", (message) => {
                    console.log("end_tutorial", "received on", room.name, message);
                    this.setView(ViewTypes.Default);
                });
                room.onMessage("update_timer", (message) => {
                    console.log("update_timer", "received on", room.name, message);
                    this.setState({ roundTimer: message.count, });
                });
                room.onMessage("start_playing", (message) => {
                    console.log("start_playing", "received on", room.name, message);
                });
                room.onMessage("show_question", (message) => {
                    console.log("show_question", "received on", room.name, message);
                    this.doNewQuestion(message.question);
                    this.setState({ submittedAnswers: false, submittedPinkcow: false, });
                });

                room.onMessage("show_answers", (message) => {
                    console.log("show_answers", "received on", room.name, message);
                    this.doAnswers();
                });
                room.onMessage("change_game", (message) => {
                    console.log("change_game", "received on", room.name, message);
                    this.goToLobby();
                });
                room.onMessage("host_joined_lobby", (message) => {
                    console.log("host_joined_lobby", "received on", room.name, message);
                    this.goToLobby();
                });
                room.onMessage("game_starting", (message) => {
                    console.log("game_starting", "received on", room.name, message);
                    if (message.gameId != gameId) {
                        this.goToLobby();
                    }
                });
                room.onMessage("catchup", (message) => {
                    console.log("catchup", "received on", room.name, message);
                    if (message.data.id == this.state.myId) {
                        //this.doCatchup(message.gameState, message.data);
                    }
                });
                room.onMessage("start_pink_cow", (message) => {
                    console.log("start_pink_cow", "received on", room.name, message);
                    if (this.state.isHerdWrangler) {
                        this.showAssignPinkCow(message.players);
                    }
                });
                room.onMessage("skip_answers_chosen", (message) => {
                    console.log("skip_answers_chosen", "received on", room.name, message);
                    if (this.state.isHerdWrangler) {
                        this.showAssignPinkCow(message.players);
                    }
                });
                room.onMessage("assign_pink_cow", (message) => {
                    console.log("assign_pink_cow", "received on", room.name, message);
                    if (this.state.isHerdWrangler) {
                        this.setState({ assignPinkCow: false, answerTitle: "", });
                    }
                });
                room.onMessage("skip_assign_pink_cow", (message) => {
                    console.log("skip_assign_pink_cow", "received on", room.name, message);
                    if (this.state.isHerdWrangler) {
                        this.setState({ assignPinkCow: false, answerTitle: "", });
                    }
                });
                room.onMessage("game_over", (message) => {
                    console.log("game_over", "received on", room.name, message);
                    setTimeout(() => {
                        this.setState({ title: "Waiting for host to start another game" });
                    }, 10000);
                });
                room.onMessage("force_answer", (message) => {
                    console.log("force_answer", "received on", room.name, message);
                    this.submitAnswer(true);
                });
                room.onMessage("force_award_points", (message) => {
                    console.log("force_award_points", "received on", room.name, message);
                    if (this.state.isHerdWrangler) {
                        this.submitSelectedAnswers();
                    }
                });
                room.onMessage("force_assign_cow", (message) => {
                    console.log("force_assign_cow", "received on", room.name, message);
                    if (this.state.isHerdWrangler) {
                        this.submitPinkCow();
                    }
                });

                room.onMessage("show_tutorial", (message) => {
                    console.log("show_tutorial", "received on", room.name, message);
                    this.setView(ViewTypes.Tutorial);
                });

                room.onMessage("begin_tutorial", (message) => {
                    console.log("begin_tutorial", "received on", room.name, message);
                });
                room.onMessage("new_game", (message) => {
                    console.log("new_game", "received on", room.name, message);
                    this.setState({ title: "" });
                });
                room.onMessage("toggle_pause", (message) => {
                    console.log("toggle_pause", "received on", room.name, message);
                    this.setState({ isPaused: message.pause });
                });

                room.onMessage("location_confirmed", (message) => {
                    console.log("location_confirmed", "received on", room.name, message);
                    this.setState({ gotLocationPing: true, });
                });

                room.onError((code, message) => {
                    console.log(this.client.id, "couldn't join", room.name);
                    LoggingService.streamLog(this.state.logStreamId, `Player ${this.state.myId} OnError at Herd Mentality, code: ${code} Message: ${JSON.stringify(message)}`);
                    //LoggingService.logError(message, code);
                });
                room.onLeave((code) => {
                    console.log(this.client.id, "left", room.name);
                    LoggingService.streamLog(this.state.logStreamId, `Player ${this.state.myId} Left Herd Mentality, Code: ${code}`);

                    this.setState({ connected: false, });
                    if (!this.state.redirectURL) {
                        if (code == 4050) {
                            this.setState({ redirect: true, redirectURL: `${this.getRedirectURL()}/` });
                            if (this.locationCheckInterval) clearInterval(this.locationCheckInterval);
                        } else {
                            this.doReconnect();
                        }
                    } else {
                        setTimeout(() => {
                            this.setState({ redirect: true, });
                        }, 1500);
                    }

                });
            }).catch(e => {
                console.log("JOIN ERROR", e);
                this.setState({ redirect: true, redirectURL: `${this.getRedirectURL()}/` });
                const message = e.message ? e.message : "An error occured joining Herd.";
                if (this.state.logStreamId.length > 0) LoggingService.streamLog(this.state.logStreamId, `Player ${this.state.myId} OnJoinError at Herd Mentality: ${JSON.stringify(e)}`);
                //LoggingService.logError(message, e);
                if (this.locationCheckInterval) clearInterval(this.locationCheckInterval);
            });
        }

    }

    render() {
        if (this.state.redirectURL) {
            return (
                <React.Fragment>
                    <div id="clientContainer" className={styles.clientContainer}>
                        <Loading loadingText={"Sending you to the lobby!"} />
                    </div>

                    <div style={{ opacity: 0 }}>
                        {
                            this.state.redirect ?
                                <Route path="/" render={() => (window.location = this.state.redirectURL)} />
                                :
                                null
                        }
                    </div>
                </React.Fragment>
            )
        }
        return (
            <div>
                <div id="clientContainer" className={styles.clientContainer}>
                    {
                        this.state.room ?
                            this.state.connected ?
                                <React.Fragment>
                                    {
                                        this.state.player?.primaryPlayer &&
                                        <ClientMenuIcon room={this.state.room} />
                                    }
                                    {
                                        this.state.connectionIssue &&
                                        <div className={styles.connectionIssueContainer}>
                                            <div className={styles.connectionText}>There might be an issue with your connection...<br />Click below to refresh!</div>
                                            <div className={styles.refreshButton} onClick={() => window.location.reload()}>&#x21bb;</div>
                                        </div>
                                    }
                                    {
                                        this.state.isPaused &&
                                        <div className={styles.pauseContainer}>
                                            <div className={styles.pauseText}>Paused</div>
                                        </div>
                                    }
                                    {
                                        this.state.showDefaultView &&
                                        <ClientContent room={this.state.room} player={this.state.player} title={this.state.title} content={this.state.content} hostConnected={this.state.hostConnected} players={this.state.players} />
                                    }
                                    {
                                        this.state.showTutorialView &&
                                        <div className={styles.skipContainer} onClick={() => this.skipTutorial()}>
                                            <div className={styles.skipButton}>Skip Tutorial</div>
                                        </div>
                                    }
                                    {
                                        this.state.showQuestionView &&
                                        <React.Fragment>
                                            {/*<div className={styles.roundTimer}>{this.state.roundTimer}</div>*/}
                                            <div className={styles.questionSection}>
                                                <div className={styles.questionBox}>
                                                    <div className={styles.questionText}>{this.state.question.question}</div>
                                                    <img src={questionFooter} className={styles.questionFooter} />
                                                </div>
                                                {/*<div className={styles.hintText}>Write something others would choose!</div>*/}
                                                <input type="text" maxLength={50} onChange={this.updateAnswer} placeholder="Write something others would choose!" className={styles.answerBox} />
                                                {/*<div className={styles.answerBox}>*/}
                                                {/*    <ContentEditable*/}
                                                {/*        html={this.state.answer}*/}
                                                {/*        disabled={false}*/}
                                                {/*        onChange={this.updateAnswer}*/}
                                                {/*        className={styles.innerBox}*/}
                                                {/*        placeholder="Write something others would choose!"*/}
                                                {/*    />*/}
                                                {/*</div>*/}
                                                <div onClick={() => this.submitAnswer()} className={styles.submitButton}>SUBMIT</div>
                                            </div>
                                        </React.Fragment>
                                    }
                                    {
                                        this.state.showMooView &&
                                        <div className={styles.mooSection} onClick={() => { this.state.canMoo ? this.doMoo(false) : console.log("paused moo") }}>
                                            <Lottie
                                                options={mooAnim}
                                                height="50vh"
                                                isClickToPauseDisabled={true}
                                                isPaused={this.state.mooIsPaused}
                                                isStopped={this.state.mooIsPaused}
                                                eventListeners={[
                                                    {
                                                        eventName: 'DOMLoaded',
                                                        callback: () => {
                                                            console.log("DOMLoaded")
                                                        }
                                                    },
                                                    {
                                                        eventName: 'complete',
                                                        callback: () => {
                                                            console.log("moo complete")
                                                            this.state.canMoo ? this.doMoo(true) : console.log("paused moo")
                                                        }
                                                    },
                                                ]}
                                            />
                                        </div>
                                    }
                                    {
                                        this.state.showAnswersView && this.state.isHerdWrangler &&
                                        <div className={styles.answersSection}>
                                            <div className={`${styles.title} ${this.state.assignPinkCow ? styles.pink : ""}`}>{this.state.answerTitle}</div>
                                            <div className={styles.answerList}>
                                                {
                                                    this.state.players.map((x) => {
                                                        return <div onClick={x.herdData.confirmed ? null : () => this.selectAnswer(x.id)} className={`${styles.answer} ${x.herdData.selected ? this.state.assignPinkCow ? styles.pinkSelected : styles.selected : ""} ${x.herdData.confirmed ? styles.confirmed : ""}`}>
                                                            <div className={styles.answerText}>{x.herdData.answer}</div>
                                                        </div>
                                                    })
                                                }
                                            </div>
                                            <div onClick={() => this.state.assignPinkCow ? this.submitPinkCow() : this.submitSelectedAnswers()} className={styles.submitButton}>SUBMIT</div>
                                            <div onClick={() => this.state.assignPinkCow ? this.skipPinkCow() : this.skipSelectedAnswers()} className={`${styles.submitButton} ${styles.smaller}`}>{this.state.assignPinkCow ? "NO PINK COW" : "NO MAJORITY"}</div>
                                        </div>
                                    }
                                </React.Fragment>
                                :
                                <Loading loadingText={"Trying to reconnect you..."} noBg={true} hideLoader={false} />
                            :
                            <Loading loadingText={"Connecting you to the game..."} noBg={false} hideLoader={false} />
                    }
                </div>
            </div>
        );
    }
}
